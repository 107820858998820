@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.event-container {
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #fcfcfc;
}

.file-upload-card {
  border-radius: 10px;
  /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3); */
  width: 100%;
  height: 260px;
  background-color: #ffffff;
  padding: 10px 30px 40px;
}

.file-upload-card h3 {
  font-size: 22px;
  font-weight: 600;
  
}

.drop_box {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}

.drop_box-image {
  margin: 10px 0;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}

.drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}

.switch-field {
	display: flex;
	margin-bottom: 36px;
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #ffffff;
	color: rgb(0, 0, 0);
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid rgba(27, 24, 219, 0.2);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}


.switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
}

.file-upload-preview {
  display: flex;
  flex-wrap: wrap;
  /* Ensures responsiveness */
  margin-top: 20px;
}

.file-upload-section,
.preview-section {
  flex: 1 1 100%;
  /* Full-width on smaller screens */
  padding: 10px;
}

@media (min-width: 768px) {

  .file-upload-section,
  .preview-section {
    flex: 1 1 50%;
    /* Half-width on medium and larger screens */
  }
}

.landscape-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: auto;
  max-width: 100%;
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.landscape-image {
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.no-media {
  text-align: center;
  color: #888;
  font-style: italic;
}

.file-upload-btn {
  background-color: #ED3C5D;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
  border-radius: 4px;
}

.file-upload-btn:hover {
  background-color: #ffffff;
  color: #ED3C5D;
  outline: 1px solid #ED3C5D;
}

/* .file-upload-btn {
  text-decoration: none;
  background-color: #ED3C5D;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
}

.file-upload-btn:hover{
  text-decoration: none;
  background-color: #ffffff;
  color: #005af0;
  padding: 10px 20px;
  border: none;
  outline: 1px solid #010101;
}
.form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
} */

/* .drop_box {
  text-align: center;
  padding: 20px;
  border: 2px dashed #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 10px;
} */


