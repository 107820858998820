.chat-window {
  height: 70vh;
  overflow-y: auto;
  position: relative;
}

.chat-messages {
  max-height: 63vh;
  min-height: 63vh;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  /* background: #f9f1f4; */
  background: #ffe4ed;
}

.btn-place {
  margin-left: inherit !important;
}

.element {
  margin-top: auto;
  /* Adjust the value as needed */
  padding: 11px 18px;
}

.image-size {
  width: 40px !important;
}

.image-size-info {
  width: 30px !important;
}

.fu-btn {
  margin-left: inherit !important;
  padding-left: 1% !important;
}

.chat-messages {
  display: flex;
  flex-direction: column;
}

.chat-message {
  display: flex;
}


/* .chat-message-count {
  background: #ED3c5D;
  margin-left: 190px;
  color: white;
  border-radius: 20px 20px 20px 20px;
  padding: 5px;
} */

.chat-message-count {
  background-color: #ED3c5D;
  color: #fff;
  padding: 3px 10px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 35%;
  height: 30px;
}

.chat-message.left {
  justify-content: flex-start;
}

.chat-message.right {
  justify-content: flex-end;
}

.message-content {
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 60%;
  position: relative;
}

.message-right {
  border-bottom-right-radius: 0;
}

.message-left {
  border-bottom-left-radius: 0;
}

.message-content p {
  margin: 0;
}

.message-right p {
  background-color: #ED3C5D;
  padding: 8px 16px;
  border-radius: 10px;
  color: white;
  margin-bottom: 15px;
}

.message-left p {
  background-color: #fff;
  padding: 8px 16px;
  border-radius: 10px;
  color: #303030;
  margin-bottom: 15px;
}

.time {
  font-size: 0.8em;
  color: #707070;
  margin-top: 5px;
  display: block;
  text-align: right;
  font-size: 11px;
}

.time-right {
  text-align: right;
}

.time-left {
  text-align: left;
}

.chat-header {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 10px 5px;
}

.chat-emoji {
  position: absolute;
  bottom: 15px;
}

.emoji-pikker {
  position: absolute;
  top: 9px;
  left: 26px;
  font-size: 22px;
  color: #c7c7c7;
}

.message-input {
  padding-left: 38px !important;
}

.emoji-picker {
  position: absolute;
  z-index: 1000;
  /* Ensure picker is above other elements */
}

.chat-emoji-list {
  position: absolute;
  left: 15px;
  bottom: 80px;
}

.date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  position: relative;
}

.date-separator::before,
.date-separator::after {
    content: "";
    flex: 1;
    border-top: 1px solid #ccc;
    margin: 0 10px;
}

.date-separator span {
  font-size: 14px;
  color: #666;
  text-transform: uppercase;
  padding: 0 10px;
  background-color: #fff;
}

.date-separator::before,
.date-separator::after {
  display: block;
  content: "";
}

.msg-option {
  cursor: pointer;
  color: #707070;
}

.msg-dropdown .btn-secondary {
  padding: 0 6px;
  background: none !important;
  border: none;
  outline: none;
  position: absolute;
}

.left-drop .msg-dropdown .btn-secondary {
  right: -25px;
}

.right-drop .msg-dropdown .btn-secondary {
  right: -5px;
}

.user-profile {
  font-size: 22px;
  padding: 11px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: rgb(99, 115, 129);
  background-color: rgb(196, 205, 213);
}

.btn-back {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: #ED3C5D !important;
}

.btn-back .fa-arrow-left {
  margin-right: 5px;
}

.file-pikker {
    position: absolute;
    top: 9px;
    right: 26px;
    font-size: 22px;
    color: #c7c7c7;
    cursor: pointer;
}

.sub-loader{
    position: absolute !important;
}

@media (min-width: 768px) {
  .d-md-none {
      display: none !important;
  }
  .d-md-block {
      display: block !important;
  }
}
