.flow-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh; 
}

.grow-container {
    display: flex;
    flex-grow: 1;
    height: 100vh;
}

.panel-button {
    margin: 0.5rem;
    background-color: #6b7280;
    color: #ffffff; 
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s;
}
  
.panel-button:hover {
    background-color: #4b5563;
}

.sidebar-container {
    border-right: 2px solid #e5e7eb;
    padding: 1rem; 
    font-size: 0.875rem; 
    background-color: #f3f4f6;
    width: 20rem;
    height: 100vh;
    overflow-y: scroll; 
    color: #000000; 
}

.update-label {
    font-size: 1.25rem; /* Equivalent to text-xl */
    margin-bottom: 0.5rem; /* Equivalent to mb-2 */
    color: #1e3a8a; /* Equivalent to text-blue-900 */
}

.node-label {
    display: block; /* Equivalent to block */
    margin-top: 0.5rem; /* Equivalent to mb-2 */
    margin-bottom: 0.5rem; /* Equivalent to mb-2 */
    font-size: 0.875rem; /* Equivalent to text-sm */
    font-weight: 500; /* Equivalent to font-medium */
    color: #111827; /* Equivalent to text-gray-900 (dark gray color) */
}

.custom-input {
    display: block; /* Equivalent to block */
    width: 100%; /* Equivalent to w-full */
    padding-top: 0.5rem; /* Equivalent to pt-2 */
    padding-left: 0.75rem; /* Equivalent to px-3 */
    padding-right: 0.75rem; /* Equivalent to px-3 */
    padding-bottom: 0.75rem; /* Equivalent to pb-3 */
    color: #4a5568; /* Equivalent to text-gray-700 (dark gray color) */
    border: 1px solid #d1d5db; /* Equivalent to border and border-gray-300 (light gray color) */
    border-radius: 0.5rem; /* Equivalent to rounded-lg */
    background-color: #ffffff; /* Equivalent to bg-white */
    transition: border-color 0.2s; /* Optional: Smooth transition for border color change */
}

.add-group {
    display: flex; /* Equivalent to flex */
    align-items: center; /* Equivalent to items-center */
    margin-bottom: 0.5rem; /* Equivalent to mb-2 */
}
  
  
  /* Focus state */
.custom-input:focus {
    outline: none; /* Equivalent to focus:outline-none */
    border-color: #6b7280; /* Equivalent to focus:border-gray-500 (medium gray color) */
}

.delete-btn {
    position: absolute; /* Equivalent to absolute */
    right: 1.75rem; /* Equivalent to right-7 */
    margin-left: 0.5rem; /* Equivalent to ml-2 */
    background-color: #f56565; /* Equivalent to bg-red-500 (red color) */
    color: #ffffff; /* Equivalent to text-white */
    border-radius: 0.25rem; /* Equivalent to rounded */
    padding: 0.25rem; /* Equivalent to p-1 */
    transition: background-color 0.3s; /* Optional: Smooth transition for background color change */
    z-index: 111;
  }

  .delete-button{            
    position: absolute !important; /* Equivalent to absolute */
    right: 0; /* Equivalent to right-7 */
    top: 5px;
    margin-left: 0.5rem; /* Equivalent to ml-2 */
    background-color: #f56565; /* Equivalent to bg-red-500 (red color) */
    color: #ffffff; /* Equivalent to text-white */
    border-radius: 0.25rem; /* Equivalent to rounded */
    padding: 0.25rem; /* Equivalent to p-1 */
    transition: background-color 0.3s; /* Optional: Smooth transition for background color change */
    z-index: 111;
  }
  
.delete-btn:hover, .delete-button:hover {
    background-color: #e53e3e; /* Equivalent to hover:bg-red-600 (darker red color) */
}


.add-button {
    margin-top: 0.5rem; /* Equivalent to mt-2 */
    width: 3.5rem; /* Equivalent to w-14 */
    background-color: #3b82f6; /* Equivalent to bg-blue-500 (blue color) */
    color: #ffffff; /* Equivalent to text-white */
    border-radius: 0.25rem; /* Equivalent to rounded */
    padding: 0.5rem; /* Equivalent to p-2 */
    transition: background-color 0.3s; /* Optional: Smooth transition for background color change */
}
  
.add-button:hover {
    background-color: #2563eb; /* Equivalent to hover:bg-blue-600 (darker blue color) */
}

.go-back-btn {
    margin-top: 1rem; /* Equivalent to mt-4 */
    background-color: #6b7280; /* Equivalent to bg-gray-500 (gray color) */
    color: #ffffff; /* Equivalent to text-white */
    border-radius: 0.25rem; /* Equivalent to rounded */
    padding: 0.5rem; /* Equivalent to p-2 */
    margin-left: 0.5rem; /* Equivalent to mx-2 */
    margin-right: 0.5rem; /* Equivalent to mx-2 */
    transition: background-color 0.3s; /* Optional: Smooth transition for background color change */
}
  
.go-back-btn:hover {
    background-color: #4b5563; /* Equivalent to hover:bg-gray-600 (darker gray color) */
}
  

.sidebar-btn {
    background-color: #ffffff; /* Equivalent to bg-white */
    padding: 0.75rem; /* Equivalent to p-3 */
    border: 2px solid #6b7280; /* Equivalent to border-2 and border-gray-500 (gray color) */
    border-radius: 0.25rem; /* Equivalent to rounded */
    cursor: move; /* Equivalent to cursor-move */
    display: flex; /* Equivalent to flex */
    justify-content: center; /* Equivalent to justify-center */
    align-items: center; /* Equivalent to items-center */
    color: #6b7280; /* Equivalent to text-gray-500 (gray color) */
    margin-top: 1rem; /* Equivalent to mt-4 */
    transition: background-color 0.2s, color 0.2s; /* Equivalent to transition-colors and duration-200 */
}
  
.sidebar-btn:hover {
    background-color: #6b7280; /* Equivalent to hover:bg-gray-500 (gray color) */
    color: #ffffff; /* Equivalent to hover:text-white */
}
  
.custom-handle {
    width: 0.25rem; /* Equivalent to w-1 */
    border-radius: 9999px; /* Equivalent to rounded-full */
    background-color: #6b7280; /* Equivalent to bg-gray-500 (gray color) */
}

.web-theme-color {
    background-color: #ed3c5d !important;
}

.start-node {
    text-align: center; /* Equivalent to text-center */
    color: #000000; /* Equivalent to text-black (black color) */
    font-size: 0.75rem; /* Equivalent to text-xs */
    font-weight: bold; /* Equivalent to font-bold */
    border-top-left-radius: 0.375rem; /* Equivalent to rounded-t-md (medium radius) */
    border-top-right-radius: 0.375rem; /* Equivalent to rounded-t-md (medium radius) */
    color: #ffffff; /* Equivalent to text-white (overrides text-black) */    
}

.agent-node {
    text-align: center; /* Equivalent to text-center */
    color: #000000; /* Equivalent to text-black (black color) */
    font-size: 0.75rem; /* Equivalent to text-xs */
    font-weight: bold; /* Equivalent to font-bold */
    border-top-left-radius: 0.375rem; /* Equivalent to rounded-t-md (medium radius) */
    border-top-right-radius: 0.375rem; /* Equivalent to rounded-t-md (medium radius) */
    color: #ffffff; /* Equivalent to text-white (overrides text-black) */    
}

.delete-node {
    position: absolute; /* Equivalent to absolute */
    width: 1rem; /* Equivalent to w-4 */
    height: 1rem; /* Equivalent to h-4 */
    top: -0.75rem; /* Equivalent to -top-3 */
    right: -0.75rem; /* Equivalent to -right-3 */
    background-color: #f56565; /* Equivalent to bg-red-500 (red color) */
    color: #ffffff; /* Equivalent to text-white */
    font-size: 0.875rem; /* Equivalent to text-sm */
    display: flex; /* Equivalent to flex */
    align-items: center; /* Equivalent to items-center */
    justify-content: center; /* Equivalent to justify-center */
    border-radius: 9999px; /* Equivalent to rounded-full */
    transition: background-color 0.3s; /* Optional: Smooth transition for background color change */
}
  
.delete-node:hover {
    background-color: #c53030; /* Equivalent to hover:bg-red-700 (darker red color) */
}

.custom-border {
    border-style: solid; /* Equivalent to border-solid */
    border-width: 2px; /* Equivalent to border-2 */
    border-color: #6366f1; /* Equivalent to border-indigo-500 (full opacity) */
}
  
.start-box, .condition-box {
    width: 4rem; /* Equivalent to w-16 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to shadow-md */
    border-radius: 0.375rem; /* Equivalent to rounded-md */
    background-color: #ffffff; /* Equivalent to bg-white */
}

.agent-box {
    width: 8rem; /* Equivalent to w-16 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to shadow-md */
    border-radius: 0.375rem; /* Equivalent to rounded-md */
    background-color: #ffffff; /* Equivalent to bg-white */
}

.condition-box {
    width: 10rem;
}
  
  
.condition-header {
    max-height: 100%; /* Equivalent to max-h-max */
    padding-left: 0.5rem; /* Equivalent to px-2 */
    padding-right: 0.5rem; /* Equivalent to px-2 */
    padding-top: 0.25rem; /* Equivalent to py-1 */
    padding-bottom: 0.25rem; /* Equivalent to py-1 */
    text-align: left; /* Equivalent to text-left */
    color: #ffffff; /* Equivalent to text-white */
    font-size: 0.75rem; /* Equivalent to text-xs */
    font-weight: bold; /* Equivalent to font-bold */
    border-top-left-radius: 0.375rem; /* Equivalent to rounded-t-md (medium radius) */
    border-top-right-radius: 0.375rem; /* Equivalent to rounded-t-md (medium radius) */    
}

.add-label {
    font-size: 0.75rem; /* Equivalent to text-xs */
    font-weight: normal; /* Equivalent to font-normal */
    color: #000000; /* Equivalent to text-black */
    border: 1px solid #d1d5db; /* Equivalent to border and border-gray-300 (gray color) */
    border-radius: 0.25rem; /* Equivalent to rounded */
    text-align: center; /* Equivalent to text-center */
}

.condition-list {
    list-style-type: disc; /* Equivalent to list-disc */
    padding-left: 1.5rem; /* Equivalent to px-6 */
    font-size: 0.75rem; /* Equivalent to text-xs */
}
  
.text-type {
    font-size: 0.75rem; /* Equivalent to text-xs */
    font-weight: normal; /* Equivalent to font-normal */
    color: #000000; /* Equivalent to text-black */
}
  
.text-node-input {
    font-size: 0.75rem; /* Equivalent to text-xs */
    font-weight: normal; /* Equivalent to font-normal */
    color: #000000; /* Equivalent to text-black */
    border: 1px solid #d1d5db; /* Equivalent to border and border-gray-300 (gray color) */
    border-radius: 0.25rem; /* Equivalent to rounded (small radius) */
    text-align: center; /* Equivalent to text-center */
}

.text-node-header {
    max-height: 100%; /* Equivalent to max-h-max */
    padding-left: 0.5rem; /* Equivalent to px-2 */
    padding-right: 0.5rem; /* Equivalent to px-2 */
    padding-top: 0.25rem; /* Equivalent to py-1 */
    padding-bottom: 0.25rem; /* Equivalent to py-1 */
    text-align: left; /* Equivalent to text-left */
    color: #ffffff; /* Equivalent to text-white (overrides text-black) */
    font-size: 0.75rem; /* Equivalent to text-xs */
    font-weight: bold; /* Equivalent to font-bold */
    border-top-left-radius: 0.375rem; /* Equivalent to rounded-t-md (medium radius) */
    border-top-right-radius: 0.375rem; /* Equivalent to rounded-t-md (medium radius) */
    background-color: #4fd1c5; /* Equivalent to bg-teal-300 (teal color) */
}
  
.condition-list li input{
    padding: 0;
    height: 20px;
    border: none;
    text-align: left;
    color: #000000;
}
  

.choose-height{
    height: 50px !important;
  }
  

.node-preview{
    font-size: 12px;
    max-width: 100%;
    word-wrap: break-word;
    padding: 0px 5px;
}

.image-node:last-child, .pdf-node:last-child {
    margin-bottom: 10px;
}
  
.upload-button{
    width: 115px;
    border: none;    
}


  /* Responsive styles for large screens */
@media (min-width: 1024px) {
    .flow-container {
        flex-direction: row; /* lg:flex-row is already covered by flex-direction: row */
    }
}
  
