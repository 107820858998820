.org-wp-flow-hero-container {
    min-height: 100vh;
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops));
    --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-gradient-to: #fff var(--tw-gradient-to-position)
}

.org-wp-flow-hero-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: .2
}

.org-wp-flow-hero-content {
    position: relative;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding: 5rem 1rem 6rem;
    text-align: center
}

@media (min-width: 640px) {
    .org-wp-flow-hero-content {
        padding-left:1.5rem;
        padding-right: 1.5rem
    }
}

@media (min-width: 1024px) {
    .org-wp-flow-hero-content {
        padding-left:2rem;
        padding-right: 2rem
    }
}

.org-wp-flow-hero-title {
    margin-bottom: 1.5rem;
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity))
}

@media (min-width: 768px) {
    .org-wp-flow-hero-title {
        font-size:3.75rem;
        line-height: 1
    }
}

.org-wp-flow-hero-subtitle {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    max-width: 48rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity))
}

@media (min-width: 768px) {
    .org-wp-flow-hero-subtitle {
        font-size:1.5rem;
        line-height: 2rem
    }
}

.org-wp-flow-button-primary {
    display: flex;
    align-items: center;
    gap: .5rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
    padding: 1rem 2rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s
}

.org-wp-flow-button-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity))
}

.org-wp-flow-event-card {
    overflow: hidden;
    border-radius: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.org-wp-flow-event-content {
    padding: 2rem
}

@media (min-width: 768px) {
    .flow-event-content {
        width:50%
    }
}

.org-wp-flow-event-title {
    margin-bottom: 1rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity))
}

.org-wp-flow-event-detail {
    display: flex;
    align-items: center;
    gap: .75rem;
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))
}

.org-wp-flow-event-icon {
    height: 1.25rem;
    width: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity))
}

.org-wp-flow-benefits-section {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity));
    padding-top: 4rem;
    padding-bottom: 4rem
}

.org-wp-flow-benefit-card {
    border-radius: .75rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 1.5rem;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.org-wp-flow-footer {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity));
    padding-top: 3rem;
    padding-bottom: 3rem;
    --tw-text-opacity: 1;
    color: #eff6ff
}

.org-wp-flow-footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding-left: 1rem;
    padding-right: 1rem
}

@media (min-width: 640px) {
    .flow-footer-content {
        padding-left:1.5rem;
        padding-right: 1.5rem
    }
}

@media (min-width: 1024px) {
    .flow-footer-content {
        padding-left:2rem;
        padding-right: 2rem
    }
}

.org-wp-flow-events-container {
    padding-bottom: 0
}

.org-wp-flow-events-header {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.org-wp-flow-back-button {
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s
}

.org-wp-flow-back-button:hover {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity))
}

.org-wp-flow-search-container {
    position: relative;
    flex: 1 1 0%
}

.org-wp-flow-search-input {
    width: 100%;
    border-radius: .5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    padding: .5rem 1rem .5rem 2.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s
}

.org-wp-flow-search-input:focus {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity))
}

.org-wp-flow-search-container svg {
    position: absolute;
    left: .75rem;
    top: 50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.org-wp-flow-filter-container {
    position: relative;
    min-width: 200px
}

.org-wp-flow-filter-select {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: .5rem 1rem .5rem 2.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .2s
}

.org-wp-flow-filter-select:focus {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity))
}

.org-wp-flow-filter-container svg {
    position: absolute;
    left: .75rem;
    top: 50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
html,:host {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
    font-feature-settings: normal;
    font-variation-settings: normal;
    -webkit-tap-highlight-color: transparent
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1,h2,h3,h4,h5,h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b,strong {
    font-weight: bolder
}

code,kbd,samp,pre {
    font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,input,optgroup,select,textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,select {
    text-transform: none
}

button,input:where([type=button]),input:where([type=reset]),input:where([type=submit]) {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,dl,dd,h1,h2,h3,h4,h5,h6,hr,figure,p,pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,ul,menu {
    list-style: none;
    margin: 0;
    padding: 0
}

dialog {
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,svg,video,canvas,audio,iframe,embed,object {
    display: block;
    vertical-align: middle
}

img,video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

.org-wp-absolute {
    position: absolute
}

.org-wp-relative {
    position: relative
}

.org-wp-right-4 {
    right: 1rem
}

.org-wp-top-4 {
    top: 1rem
}

.org-wp-mx-auto {
    margin-left: auto;
    margin-right: auto
}

.org-wp-mb-12 {
    margin-bottom: 3rem
}

.org-wp-mb-3 {
    margin-bottom: .75rem
}

.org-wp-mb-4 {
    margin-bottom: 1rem
}

.org-wp-mb-6 {
    margin-bottom: 1.5rem
}

.org-wp-mb-8 {
    margin-bottom: 2rem
}

.org-wp-mt-16 {
    margin-top: 4rem
}

.org-wp-mt-6 {
    margin-top: 1.5rem
}

@media (min-width: 768px) {
    .md\:flex {
        display: flex;
    }
}

@media (min-width: 768px) {
    .md\:w-1\/2 {
        width: 50%;
    }
}

.org-wp-mt-8 {
    margin-top: 2rem
}

.org-wp-flex {
    display: flex
}

.org-wp-grid {
    display: grid
}

.org-wp-h-5 {
    height: 1.25rem
}

.org-wp-h-64 {
    height: 16rem
}

.org-wp-h-full {
    height: 100%
}

.org-wp-min-h-screen {
    min-height: 100vh
}

.org-wp-w-5 {
    width: 1.25rem
}

.org-wp-org-wp-w-full {
    width: 100%
}

.org-wp-max-w-7xl {
    max-width: 80rem
}

.org-wp-max-w-md {
    max-width: 28rem
}

.org-wp-flex-col {
    flex-direction: column
}

.org-wp-gap-4 {
    gap: 1rem
}

.org-wp-gap-8 {
    gap: 2rem
}

.org-wp-space-y-2>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse))
}

.org-wp-space-y-3>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse))
}

.org-wp-space-y-4>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.org-wp-rounded-full {
    border-radius: 9999px
}

.org-wp-rounded-lg {
    border-radius: .5rem
}

.org-wp-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
}

.org-wp-border-2 {
    border-width: 2px
}

.org-wp-border-t {
    border-top-width: 1px
}

.org-wp-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity))
}

.org-wp-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity))
}

.org-wp-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity))
}

.org-wp-bg-gradient-to-b {
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops))
}

.org-wp-from-blue-50 {
    --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.org-wp-to-white {
    --tw-gradient-to: #fff var(--tw-gradient-to-position)
}

.org-wp-object-cover {
    -o-object-fit: cover;
    object-fit: cover
}

.org-wp-p-6 {
    padding: 1.5rem
}

.org-wp-px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.org-wp-org-wp-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.org-wp-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
}

.org-wp-py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.org-wp-org-wp-py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.org-wp-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}

.org-wp-pt-8 {
    padding-top: 2rem
}

.org-wp-text-center {
    text-align: center
}

.org-wp-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.org-wp-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.org-wp-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
}

.org-wp-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.org-wp-font-bold {
    font-weight: 700
}

.org-wp-font-semibold {
    font-weight: 600
}

.org-wp-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity))
}

.org-wp-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity))
}

.org-wp-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity))
}

.org-wp-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
}

.org-wp-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))
}

.org-wp-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity))
}

.org-wp-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.org-wp-filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.org-wp-transition-colors {
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s
}

.org-wp-duration-200 {
    transition-duration: .2s
}

.hover\:org-wp-bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity))
}

.hover\:org-wp-bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity))
}

@media (min-width: 640px) {
    .sm\:px-6 {
        padding-left:1.5rem;
        padding-right: 1.5rem
    }
}

@media (min-width: 768px) {
    .md\:flex {
        display:flex
    }

    .md\:w-1\/2 {
        width: 50%
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr))
    }

    .md\:grid-cols-3 {
        grid-template-columns: repeat(3,minmax(0,1fr))
    }

    .md\:flex-row {
        flex-direction: row
    }

    .md\:text-right {
        text-align: right
    }
}

@media (min-width: 1024px) {
    .lg\:px-8 {
        padding-left:2rem;
        padding-right: 2rem
    }
}
