.whatsapp-icon {
    height: 75px;
    width: 75px;
    border-radius: 100%;
    background: #25D366;
    color: white;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.whatsapp-icon::before{
    content: '1';
    width: 20px;
    height: 20px;
    background: red;
    color: white;
    border: none;
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;   
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.whatsapp-content {
    height: 550px;
    width: 375px;
    margin: 10px;    
    border-radius: 15px;    
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../../../../assets/images/whatsapp-bg.jpg");    
    position: relative;
}

.whatsapp-header {
    padding: 16px;
    background: #075E54;
    border-radius: 15px 15px 0 0;
    color: white; 
    display: flex;   
}

.whatsapp-header img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50px;
    margin-right: 10px;
}

.whatsapp-header h2{
    color: white;
    margin: 0;
}

.chat-bubble {
    max-width: 250px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    position: relative;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #000;
    display: inline-block;
    word-wrap: break-word; 
    white-space: normal; 
  }

  .chat-bubble::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #ffffff;
    transform: translateY(-50%);
  }

  .message-text {
    margin: 0;
    line-height: 1.4;
  }

  .whatsapp-msg{
    padding: 10px;
  }

  .whatsapp-footer{
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    background-image: url("../../../../assets/images/whatsapp-footer.png");  
    border-radius: 0 0 15px 15px;
    background-repeat: no-repeat;
    background-size: cover; 
  }
  .buttons-container {
    display: flex;
    flex-direction: column; /* Column-wise layout */
    gap: 10px; /* Spacing between buttons */
    margin-top: 10px;
  }
  
  .chat-button {
    background-color: #25d366;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .chat-button:hover {
    background-color: #1ebd58;
  }
  
  .chat-button:active {
    background-color: #128c7e;
  }
  