.phone {
    width: 310px;
    height: 653px;
    border-radius: 35px;
    position: relative;
    box-shadow: inset 0 0 15px rgba(0, 0, 0, .2), 0 40px 100px rgba(0, 0, 0, .4);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.phone:before {
    content: "";
    background: url("../../../../assets/images/whatsapp.png");
    background-size: cover;
    position: absolute;
    top: 15px;
    left: 7.5px;
    width: calc(100% - 15px);
    height: calc(100% - 30px);
    border-radius: 35px;
    box-shadow: inset 0 0 15px rgba(0, 0, 0, .2);
}

.phone:after {
    content: "";
    width: 130px;
    position: absolute;
    left: 50%;
    height: 16px;
    top: 15px;
    background: #fff;
    transform: translateX(-50%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.message-field {
    display: flex;
    transition: 0.3s ease-in-out;
    position: absolute;
    left: -100%;
}

.message-field.active {
    flex-direction: column;
    position: absolute;
    backdrop-filter: blur(15px);
    box-shadow: 10px 0 15px rgb(0 0 0 / 10%);
    width: calc(100% - 15px);
    height: calc(100% - 30px);
    border-radius: 35px;
    top: 15px;
    left: 8px;
    justify-content: center;
    transition: 0.5s ease-in-out;
}

.message-container {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    max-height: 90%;
    max-width: 500px;
    padding: 10px;
}

.cardMobile {
    background-color: white;
    color: white;
    border-radius: 0 15px 15px 15px;
    padding: 20px;
    width: 300px;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.pdf-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pdf-icon {
    background-color: #cf3e5a;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: bold;
}

.download-btn {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.content {
    margin-top: 10px;
}

h2 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
}

p {
    margin: 5px 0;
    font-size: 14px;
}

strong {
    font-weight: bold;
}

.store-link {
    margin-top: 10px;
}

.store-link a {
    color: #7ababa;
    text-decoration: none;
}

.store-link a:hover {
    text-decoration: underline;
}

.footer-text {
    font-size: 12px;
    color: #b2c9c3;
    margin-top: 10px;
}

.timeMobile {
    position: absolute;
    right: 10px;
    font-size: 12px;
    color: #636363;
    bottom: 1px;
}


/* Message bubble arrow for received message */

.message-received::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    width: 0;
    height: 0;
    border-top: 14px solid white;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    transform: rotate(-45deg);
}

.img-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileBtn {
    margin: 0 !important;
    margin-top: 5px !important;
    width: 100%;
    color:  	#25D366 !important;
    border: 1px solid 	#25D366 !important;
    background-color: #fff !important;
    padding: 3px 0px !important;
}

.img-temp {
    width: 100%;
    height: 85px;
    /* filter: blur(10px); */
}

.download-img {
    color: black;
    position: absolute;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 5px;
}

.footer-temp {
    font-size: 12px;
}


.content-body{
    font-size: 16px;
}