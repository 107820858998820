.chatbot-flow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height to center vertically */
    text-align: center;
  }
  
  .icon {
    width: 50px; /* Increase icon size */
    margin-bottom: 1rem; /* Space between icon and text */
  }
  
  .text {
    font-size: 5rem; /* Increase text size */
  }
  