.edit-modal{
    max-width: 80vw !important;
}

.edit-template-body{
    display: flex;
}

.edit-template-body .edit-card{
    width: 60vw;
}

.edit-template-body .edit-view{
    width: 40vw;
}

.truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

@media (max-width: 768px) {

    .edit-template-body{
        flex-direction: column;
    }
    .edit-template-body .edit-card{
        width: 100%;
    }
    
    .edit-template-body .edit-view{
        width: 100%;
    }
}