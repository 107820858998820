img {
    object-fit: cover;
}

.loader-color{
    color: #ED3C5D;
}

/* Remove loading text from loader */
.visually-hidden {
    display: none;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.flex {
    display: flex;
}

.invalid-feedback {
    margin: 0.25rem;
}

.cursor-pointer {
    cursor: pointer;
}

.relative {
    position: relative !important;
}

.flex-direction-column {
    display: flex;
    flex-direction: column;
}

.inputfield {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
}

.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0; 
}

.serial-number {
    width: 50px;
    padding: 10px !important;
    text-align: center;
}

.ellipsis-column {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* ellipsis(...) after specific width */
.ellipsis-message-column {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.recommended-field {
    padding: 0.625rem 0;
}

.error-message {
    width: 100%;
    display: block;
    padding: 0.25rem;
    box-sizing: border-box;
    color: #fb6340;
    font-size: 80%;
}

.email-verification-check-icon {
    font-size: 56px;
}

.email-verification-loader {
    padding: 100px;
}


.react-datetime-picker__wrapper {
    border: none !important;
}

.rdtCount,
.rdtCounterSeparator {
    padding: 0 !important;
    border: none !important;
    color: #525f7f !important;
}

.rdtTimeToggle {
    visibility: visible !important;
}

.rdtTimeToggle:before {
    display: none !important;
}

/* Item Quantity */
.item-quantity {
    font-size: 1.1em;
    margin: 0 1em;
}

/* Checkout bill and date table css */
.invoice td {
    padding: 0.5rem;
    font-size: 12px;
    white-space: pre;
}

/* Checkout products table css */
.products td {
    padding: 0.5rem;
    font-size: 14px;
}

.report-calender {
    width: 115px;
}

.report-calender {
    width: 115px;
}

.rounded-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.remove-background {
    background: none;
}

.astric {
    color: #fb6340;
}

.required-field::after {
    content: " *";
    color: red;
}

.dropdown-disabled {
    opacity: 0.5;
}

.custom-form-control:disabled {
    opacity: 0.5 !important;
}

.view-more-less {
  font-size: 0.8em;
}

.column-gap{
    display: flex;
    column-gap: 20px;
}

.customer-tag-modal-overflow{
    max-height: 450px;
    overflow: auto;
}

.bold{
    font-weight: 800 !important;
}

.all-customers-checkbox{
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 10px;

    .form-check-inline{
        margin-right: 0 !important;
    }
}

.disabled {
    pointer-events:none; 
    opacity:0.6;         
}

.welcome-pdf-input {
    .custom-form-control {
        color: transparent !important;
    }
}

.align-start{
    align-items: flex-start !important;
}

.warning-message{
    color:#ff3709
}

.flex-end{
    display: flex;
    justify-content: flex-end;
}

.center-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-policy-div{
    max-width: 80% !important;
}

.country-code{
    width: 25% !important;
    margin-right: 12px !important;
}

.facebook-widget{
    display: flex !important;
    flex-direction: column !important;
    line-height: 17px !important;

    .text-right{
        font-size: 10px !important;
    }
}

.input-charactor-count{
    position: absolute;
    font-size: 10px;
    right: 0;
    top: -16px;
}

.input-charactor-count-template{
    position: absolute;
    font-size: 10px;
    right: 15px;
    top: -16px;
}

